import { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import HomePage from "pages/home";
import ShopPage from "pages/shop";
import SearchPage from "pages/search";
import LoginPage from "pages/login";
import RegisterPage from "pages/register";
import ProductPage from "pages/product";
import CheckOutPage from "pages/checkout";
import PayDonePage from "pages/pay-done";
import AccountPage from "pages/AccountPage/AccountPage";
import AccountPass from "pages/AccountPage/AccountPass";
import AccountSavelists from "pages/AccountPage/AccountSavelists";
import { AuthContext } from "context/authContext";
import ProtectedRoute from "./protectedRoute";
import UnprotectedRoute from "./unProtectedRoute";
import PageContact from "pages/contact";
import AccountBookings from "pages/AccountPage/AccountBooking";
import SmoothScroll from "./ScrollToTop";
import smoothscroll from "smoothscroll-polyfill";
import ResetPassword from "pages/AccountPage/ResetPassword";
import ForgetPassword from "pages/AccountPage/ForgetPassword";
import AccountUpload from "pages/AccountPage/AccountUpload";
import PageBoat from "pages/boat";
import SignContractPage from "pages/contract";
import FileDownloadPage from "pages/file";
import PageRepair from "pages/repair";
import PageTool from "pages/tool";
import PayPage from "pages/payment";

export const pages: Page[] = [
  { path: "/", exact: true, component: HomePage },
  { path: "/car-rental", exact: true, component: ShopPage },
  { path: "/search", component: SearchPage },
  { path: "/car-rental/:id", component: ProductPage },
  { path: "/login", component: LoginPage },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/reset-password/:id", component: ResetPassword },
  { path: "/register", component: RegisterPage },
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayDonePage },
  { path: "/yacht-rental", component: PageBoat },
  { path: "/carrepair", component: PageRepair },
  { path: "/carparts", component: PageTool },
  { path: "/#", exact: true, component: HomePage },
  // { path: "/account", component: AccountPage },
  // { path: "/account-password", component: AccountPass },
  // { path: "/account-savelists", component: AccountSavelists },
  { path: "/contact", component: PageContact },
  { path: "/e-contract/:id", component: SignContractPage },
  { path: "/file/:id", component: FileDownloadPage },
  { path: "/payment/:id", component: PayPage },
];

export const protectedPage: Page[] = [
  { path: "/account", component: AccountPage },
  { path: "/account-upload", component: AccountUpload },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-bookings", component: AccountBookings },
];

export type classNameProps = {
  className?: string;
};

const App = () => {
  return (
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  );
};

const MyRoutes = () => {
  const { user, loadingUserData, loading } = useContext(AuthContext);
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  smoothscroll.polyfill();

  return (
    <>
      {/* <ScrollToTop /> */}
      <SiteHeader />
      <SmoothScroll> </SmoothScroll>
      <Routes>
        <Route
          path="/login"
          element={
            <UnprotectedRoute user={user} loading={loading}>
              <LoginPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute user={user} loadingUserData={loadingUserData}>
              <AccountPage />
            </ProtectedRoute>
          }
        />
        {protectedPage.map(({ component, path }) => {
          const Component: any = component;
          return (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute user={user} loadingUserData={loadingUserData}>
                  <Component />
                </ProtectedRoute>
              }
            />
          );
        })}

        {/* Other routes */}
        {pages.map(({ component, path }) => {
          const Component: any = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}

        <Route path="*" element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </>
  );
};

export default App;
