import { Tab } from "@headlessui/react";
import { FC, Fragment, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useFetch from "hooks/useFetch";
import { FetchProductDetail } from "./product";
import { useParams } from "react-router-dom";
import Loading from "components/Loading/loading";
import axiosInstance from "../utils/axiosInstance";
import ErrorPage from "./404";
import { motion } from "framer-motion";
import AirWallex from "./PayAirwallex";
import { useTranslation } from "react-i18next";
import "react-international-phone/style.css";
import Alert from "components/Alert/alert";

interface PaymentProps {
  paymentId: string;
  amount: number;
  status: string;
  paymentMethod?: string;
}

interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const { id } = useParams();
  const { t } = useTranslation("checkout");

  const { data, loading, error }: FetchProductDetail = useFetch(
    `/api/v1/payments/paymentLink/${id}`
  );
  const [payment, setPayment] = useState<PaymentProps>();
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [isLoading, setIsLoading] = useState(false);
  const [intentId, setIntentId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [startPayment, setStartPayment] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handlePayment = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/api/v1/payments/create-payment-intent",
        {
          totalPrice: data?.amount,
          currency: "HKD",
          bookingId: data?.bookingId,
        }
      );
      if (response?.status == 201 || response?.status == 200) {
        setIntentId(response.data?.id);
        setClientSecret(response.data?.client_secret);
        setStartPayment(true);
      }
    } catch (error) {
      setIsLoading(false);
      setAlert({
        show: true,
        message: `${error}`,
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
      console.error("Error in creating payment intent:", error);
      // Handle error in creating payment intent
      // Display an error message to user, for instance.
    }
  };
  // ... (keep existing render content and add payment section at the bottom):

  const renderPaymentSection = () => {
    return (
      <div className="space-y-6">
        {!paymentSuccess && (
          <>
            <h3 className="text-2xl pt-5 font-semibold">
              {t("PAYWITH", "付款方式")}
            </h3>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                {/* Credit Card Tab */}
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => setPaymentMethod("card")}
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full flex items-center justify-center focus:outline-none ${
                        selected
                          ? "bg-neutral-300 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  {paymentMethod === "card" && (
                    <AirWallex
                      totalPrice={data?.amount || 0}
                      startPayment={startPayment}
                      setStartPayment={setStartPayment}
                      intentId={intentId}
                      clientSecret={clientSecret}
                      setIsLoading={setIsLoading}
                      productId={data?.paymentId}
                      bookingId={data?.bookingId}
                      bookingInformation={data?.booking}
                      setAlert={setAlert}
                      setPaymentSuccess={setPaymentSuccess}
                    />
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <div className="pt-8">
              <ButtonPrimary onClick={handlePayment} disabled={isLoading}>
                {t("CONFIRMPAY", "確認並付款")}
                {isLoading ? (
                  <div className="ml-4">
                    <svg
                      className={`animate-spin -ml-1 mr-3 h-5 w-5`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="3"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ) : null}
              </ButtonPrimary>
            </div>
          </>
        )}
        {alert?.show && (
          <Alert
            type={alert.type}
            message={alert.message}
            className="w-full mt-5"
          />
        )}
      </div>
    );
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8  ">
        <h3 className="text-2xl font-semibold">
          {t("BOOKINGDETAIL", "訂單詳情")}
        </h3>
        <div className=" sm:flex-row sm:items-center">
          <div className="flex text-neutral-6000 dark:text-neutral-300 items-center space-x-2">
            <span className="font-medium text-neutral-900 dark:text-neutral-100">
              {t("BOOKINGID", "訂單號碼")}
            </span>
            <span className="font-medium text-neutral-900 dark:text-neutral-100">
              #{data?.booking?.bookingId}
            </span>
          </div>
          <div className="flex-shrink-0 w-full sm:w-40 mt-2">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={data?.booking?.car?.featuredImage}
                alt="Car image"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">
            {t("PAYMENTDETAIL", "付款詳情")}
          </h3>
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div> */}
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service Charge</span>
            <span>$0</span>
          </div> */}

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("CATEGORY", "類別")}</span>
            <span>
              {data?.paymentType == "rentalfee"
                ? t("RENTALFEE", {
                    defaultValue: "租金",
                  })
                : data?.paymentType == "deposit"
                ? t("DEPOSIT", {
                    defaultValue: "按金",
                  })
                : data?.paymentType == "reservefee"
                ? t("RESERVEFEE", {
                    defaultValue: "留車費用",
                  })
                : data?.paymentType == "pickupfee"
                ? t("PICKUPFEE", {
                    defaultValue: "取車費用",
                  })
                : data?.paymentType == "handlingfee"
                ? t("HANDLINGFEE", {
                    defaultValue: "手續費",
                  })
                : data?.paymentType == "returnfee"
                ? t("RETRURNFEE", {
                    defaultValue: "收車費用",
                  })
                : data?.paymentType == "fuelfee"
                ? t("FUELFEE", {
                    defaultValue: "油費",
                  })
                : data?.paymentType == "cleaningfee"
                ? t("CLEANINGFEE", {
                    defaultValue: "清潔費",
                  })
                : data?.paymentType == "maintenancefee"
                ? t("MAINTENANCEFEE", {
                    defaultValue: "維修費",
                  })
                : data?.paymentType == "towfee"
                ? t("TOWFEE", {
                    defaultValue: "拖車費",
                  })
                : data?.paymentType == "depositrefund"
                ? t("DEPOSITREFUND", {
                    defaultValue: "退回按金",
                  })
                : data?.paymentType == "tunnelfee"
                ? t("TUNNELFEE", {
                    defaultValue: "隧道費",
                  })
                : data?.paymentType == "additional"
                ? t("ADDITIONALFEE", {
                    defaultValue: "Additional Fee",
                  })
                : data?.paymentType == "other"
                ? t("OTHER", {
                    defaultValue: "Other",
                  })
                : ""}
            </span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("TOTAL", "Total")}</span>
            <span>${data?.amount}</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <Loading className="min-h-[50rem]" />
      ) : id ? (
        <motion.div
          className={`nc-PayPage ${className}`}
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
        >
          <main className="container mt-11 mb-24 lg:mb-32">
            <div className="max-w-4xl mx-auto">
              {renderContent()}
              {renderPaymentSection()}
            </div>
          </main>
        </motion.div>
      ) : (
        <ErrorPage
          link="/"
          message={t("LINKEXPIRED", "連結已失效或過期!")}
          buttonText={t("BACKHOME", "返回首頁！")}
        />
      )}
    </>
  );
};

export default PayPage;
